import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import logo from "../../images/sidebar/logo.svg";
import download from "../../images/dashboard/download.svg";
import {
  Menu,
  MenuItem,
  IconButton,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Box,
  Grid,
  Container,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import "../../style/dashboard.css";
import "../../style/gallery.css";
import "../../style/sessionDetailedView.css";
import pause from "../../images/gallery/Pause.svg";
import expired from "../../images/dashboard/no_content.svg";
import requests from "../../images/dashboard/exp_request.svg";
import { useLocation } from "react-router-dom";
import cloudFunctionRepository from "../../repositories/cloudFunctionRepository";
import Gallery from "../gallery/fileDetailedView";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../firebase";
import MultiSelect from "../gallery/multiselect";
import { downloadFiles } from "../gallery/downloadFile";

function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ padding: "20px 0px" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const DisplayLink = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [sessionData, setSessionData] = useState(null);
  const [assetsData, setAssetsData] = useState(null);
  const [expirationDate, setExpirationDate] = useState("");
  const [isExpired, setIsExpired] = useState(false);
  const [isExhausted, setIsExhausted] = useState(false);
  const [selectedTab, setSelectedTab] = useState("All");
  const [galleryVisible, setGalleryVisible] = useState(false);
  const [multiselectedFiles, setMultiselectedFiles] = useState([]);
  const [multiselectedUrls, setMultiselectedUrls] = useState([]);
  const [allFiles, setAllFiles] = useState([]);
  const [openedSharedFile, setOpenedSharedFile] = useState([]);
  const [downloadingFiles, setDownloadingFiles] = useState(new Set());

  const location = useLocation();
  useEffect(() => {
    const fetchLinks = async () => {
      const pathSegments = location.pathname.split("/s/");
      const docName = pathSegments[1];

      try {
        const linkData = await cloudFunctionRepository.getShareLinkData(
          docName
        );
        setSessionData(linkData.sessionInfo);
        setAssetsData(linkData.assetsInfo);
        setExpirationDate(linkData.expirationDate);
      } catch (error) {
        if (error.message.includes("Too many requests")) {
          setIsExhausted(true);
        } else if (error.message.includes("Share link has expired")) {
          setIsExpired(true);
        } else if (
          error.message.includes("Share link document does not exist")
        ) {
          setIsExpired(true);
        } else if (
          error.message.includes(
            "An error occurred while retrieving the share link render data"
          )
        ) {
          setIsExpired(true);
        } else {
          console.error("An unknown error occurred.");
          setIsExpired(true);
        }
      }
    };

    fetchLinks();
  }, [location.pathname]);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDownloadAll = (file) => {
    downloadFiles(file, assetsData);
    handleMenuClose();
  };

  const handleTabChange = (event) => {
    setSelectedTab(event.target.value);
  };

  const handleSelectImage = (media) => {
    if (multiselectedFiles.some((item) => item.signedUrl === media.signedUrl)) {
      setMultiselectedFiles(
        multiselectedFiles.filter((item) => item.signedUrl !== media.signedUrl)
      );
    } else {
      setMultiselectedFiles([...multiselectedFiles, media]);
    }
  };

  function handleImageClick(url) {
    if (multiselectedUrls.includes(url)) {
      setMultiselectedUrls((prevUrls) =>
        prevUrls.filter((prevUrl) => prevUrl !== url)
      );
    } else {
      setMultiselectedUrls((prevUrls) => [...prevUrls, url]);
    }
  }

  const openGallery = (media, mediaArray) => {
    logEvent(analytics, "file_open", {
      button_name: "file_open",
    });
    if (multiselectedUrls.includes(media.downloadURL)) {
      setMultiselectedUrls((prevUrls) =>
        prevUrls.filter((prevUrl) => prevUrl !== media.downloadURL)
      );
    } else {
      setMultiselectedUrls((prevUrls) => [...prevUrls, media.downloadURL]);
    }
    setAllFiles(mediaArray);
    setOpenedSharedFile([media]);
    setMultiselectedFiles([]);

    if (media.assetType.includes("video")) {
      setGalleryVisible(true);
    } else if (media.assetType.includes("image")) {
      setGalleryVisible(true);
    } else {
      setGalleryVisible(false);
    }
  };

  const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return `${day}th`;
    switch (day % 10) {
      case 1:
        return `${day}st`;
      case 2:
        return `${day}nd`;
      case 3:
        return `${day}rd`;
      default:
        return `${day}th`;
    }
  };

  const renderMedia = () => {
    let mediaArray = Object.keys(assetsData).map((key) => assetsData[key]);

    if (selectedTab === "Videos") {
      mediaArray = mediaArray.filter((media) => media.assetType === "video");
    } else if (selectedTab === "Images") {
      mediaArray = mediaArray.filter((media) => media.assetType === "image");
    }

    return (
      <div className="images_page">
        {mediaArray.map((media, index) => (
          <div key={index} className="images_page_item">
            {media.assetType === "image" && (
              <div className="image-container">
                <div className="hover_over">
                  <img
                    className="myimg"
                    src={media.thumbnailSignedUrl}
                    alt={media.globalId}
                  />
                  <div
                    className="overlay"
                    onClick={() => openGallery(media, mediaArray)}
                  ></div>
                  <input
                    type="checkbox"
                    id="myCheckbox"
                    checked={
                      !!multiselectedFiles.find(
                        (item) => item.signedUrl === media.signedUrl
                      )
                    }
                    onChange={() => {
                      handleSelectImage(media);
                      handleImageClick(media.signedUrl);
                    }}
                  />
                  <label for="myCheckbox"></label>
                </div>
              </div>
            )}
            {media.assetType === "video" && (
              <div className="image-container">
                <div className="hover_over">
                  <div className="pause_button">
                    <img src={pause}></img>
                  </div>{" "}
                  <img
                    className="myimg"
                    src={media.thumbnailSignedUrl}
                    alt={media.globalId}
                  />
                  <div
                    className="overlay"
                    onClick={() => openGallery(media, mediaArray)}
                  ></div>{" "}
                  <input
                    type="checkbox"
                    id="myCheckbox"
                    checked={
                      !!multiselectedFiles.find(
                        (item) => item.signedUrl === media.signedUrl
                      )
                    }
                    onChange={() => {
                      handleSelectImage(media);
                      handleImageClick(media.signedUrl);
                    }}
                  />
                  <label htmlFor="myCheckbox"></label>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "20px",
          backgroundColor: "#323232",
          height: "40px",
        }}
      >
        <img src={logo} alt="Connect Logo" style={{ width: "100px" }} />
        <div style={{ display: "flex", alignItems: "center" }}>
          <a
            href="/login"
            target="_blank"
            style={{ padding: "10px 40px", textDecoration: "none" }}
            className="button"
          >
            Sign In
          </a>
          <IconButton onClick={handleMenuClick} style={{ color: "white" }}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            PaperProps={{
              style: {
                marginTop: "15px",
                borderRadius: "10px",
              },
            }}
          >
            <MenuItem
              onClick={() => {
                const filesToDownload = [];
                Object.values(assetsData).forEach((index) => {
                  if (!downloadingFiles.has(index.signedUrl)) {
                    const url = index.signedUrl;
                    const name = index.globalId;
                    const thumbnail = index.thumbnailsSignedUrl;
                    const size = index.size;
                    filesToDownload.push({
                      name: name,
                      url: url,
                      size: size,
                      thumbnail: thumbnail,
                    });
                  }
                });
                if (filesToDownload.length > 0) {
                  handleDownloadAll(filesToDownload);
                }
              }}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              Download All
              <img
                src={download}
                alt="Download all"
                style={{ marginLeft: "40px" }}
              />
            </MenuItem>
          </Menu>
        </div>
      </div>

      <div style={{ marginTop: multiselectedFiles.length > 0 ? "3em" : "0em" }}>
        {multiselectedFiles.length != 0 && (
          <MultiSelect
            multiSharedFiles={multiselectedFiles}
            setMultiSharedFiles={setMultiselectedFiles}
            downloadingFiles={downloadingFiles}
            setDownloadingFiles={setDownloadingFiles}
          />
        )}
        <div>
          {!isExhausted && !isExpired && sessionData ? (
            <>
              {" "}
              <div
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  marginTop: "20px",
                  fontWeight: "700",
                }}
              >
                A{" "}
                <a
                  href="https://customsurgical.co/connect"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#8F4FFF", textDecoration: "none" }}
                >
                  MicroREC Connect
                </a>{" "}
                user has shared some files with you
              </div>
              {expirationDate ? (
                <div
                  style={{
                    color: "#999999",
                    textAlign: "center",
                    fontWeight: "700",
                    marginTop: "10px",
                  }}
                >
                  Expires{" "}
                  {(() => {
                    const date = new Date(expirationDate);
                    const day = getOrdinalSuffix(date.getDate());
                    const month = date.toLocaleString("en-GB", {
                      month: "long",
                    });
                    const year = date.getFullYear();
                    return `${day} ${month} ${year}`;
                  })()}
                </div>
              ) : (
                ""
              )}
              <Container maxWidth="lg" className="container_bottom">
                {Object.keys(sessionData).length > 0 ? (
                  <>
                    <div className="data_flex_session data_flex_session_inside">
                      <div>
                        <label className="label">PATIENT ID:</label>
                        <p className="input_style edit_modal_input">
                          {sessionData.patientId &&
                          sessionData.patientId.length > 0 ? (
                            sessionData.patientId
                          ) : (
                            <span className="no_patient_id">
                              No patient ID assigned
                            </span>
                          )}
                        </p>

                        <label className="label">DATE:</label>
                        <p className="input_style edit_modal_input">
                          {sessionData.createdAt &&
                            new Date(sessionData.createdAt).toLocaleString(
                              "en-GB",
                              {
                                day: "2-digit",
                                month: "long",
                                year: "numeric",
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                                hour12: false,
                              }
                            )}
                        </p>

                        <label className="label">TAGS:</label>

                        <div className="width_100" style={{ width: "100%" }}>
                          {sessionData.tags && sessionData.tags.length > 0 ? (
                            <div
                              className="tags_block width_100"
                              style={{ width: "100%" }}
                            >
                              {sessionData.tags?.map((tag) => (
                                <div
                                  key={`tagsBlock-${tag}`}
                                  className=" margin_top_wrap tags_div"
                                  style={{ width: "auto" }}
                                >
                                  {`${tag}`
                                    ? `${tag}`.length > 25
                                      ? `${tag}`.slice(0, 25) + "..."
                                      : `${tag}`
                                    : ""}
                                </div>
                              ))}{" "}
                            </div>
                          ) : (
                            <span className="no_tags_added_div">
                              No tags added
                            </span>
                          )}
                        </div>
                      </div>
                      <Grid
                        item
                        xs={5.8}
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <label className="label margin_bottom_05 margin_top_1">
                          Comment:
                        </label>
                        <Grid
                          style={{
                            width: "100%",
                            padding: "15px",
                            height: "100%",
                          }}
                          item
                          className="description-wrap"
                        >
                          <Typography>
                            {sessionData.description?.length > 0
                              ? sessionData.description
                              : "A comment or note about a patient, photo or video description"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>{" "}
                    <hr
                      style={{ border: "1px solid #D9D9D9", marginTop: "1em" }}
                    ></hr>
                  </>
                ) : (
                  ""
                )}
                {assetsData ? (
                  <>
                    <div className="margin_left_1">
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="media-radio-buttons-group-label"
                          value={selectedTab}
                          onChange={handleTabChange}
                          name="media-radio-buttons-group"
                        >
                          <FormControlLabel
                            value="All"
                            control={
                              <Radio
                                sx={{
                                  color: "#8F4FFF",
                                  "&.Mui-checked": { color: "#8F4FFF" },
                                }}
                              />
                            }
                            label="All"
                          />
                          <FormControlLabel
                            value="Videos"
                            control={
                              <Radio
                                sx={{
                                  color: "#8F4FFF",
                                  "&.Mui-checked": { color: "#8F4FFF" },
                                }}
                              />
                            }
                            label="Videos"
                          />
                          <FormControlLabel
                            value="Images"
                            control={
                              <Radio
                                sx={{
                                  color: "#8F4FFF",
                                  "&.Mui-checked": { color: "#8F4FFF" },
                                }}
                              />
                            }
                            label="Images"
                          />
                        </RadioGroup>
                      </FormControl>
                      <div className="media-container">{renderMedia()}</div>
                    </div>{" "}
                  </>
                ) : (
                  ""
                )}
                {galleryVisible ? (
                  <Gallery
                    galleryVisible={galleryVisible}
                    openedSharedFile={openedSharedFile}
                    setOpenedSharedFile={setOpenedSharedFile}
                    setGalleryVisible={setGalleryVisible}
                    multiselectedUrls={multiselectedUrls}
                    multiselectedFiles={multiselectedFiles}
                    setMultiselectedFiles={setMultiselectedFiles}
                    setMultiselectedUrls={setMultiselectedUrls}
                    allFiles={allFiles}
                    downloadingFiles={downloadingFiles}
                    setDownloadingFiles={setDownloadingFiles}
                    assetsData={assetsData}
                  />
                ) : (
                  ""
                )}
              </Container>
            </>
          ) : (
            ""
          )}
        </div>
        <>
          {isExpired ? (
            <div className="centered-container">
              <img src={expired}></img>
              <h2>No shared content</h2>
              <div style={{ fontSize: "20px" }}>
                Shared content can't be found<br></br>
                It may have expired.
              </div>
            </div>
          ) : isExhausted ? (
            <div className="centered-container">
              <img src={requests}></img>
              <h2>Too many requests have been made in a short time.</h2>
              <div style={{ fontSize: "20px" }}>
                Please wait a moment and try again in 1 minute
              </div>
            </div>
          ) : (
            ""
          )}
        </>
      </div>
    </>
  );
};

export default DisplayLink;
