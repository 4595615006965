/**
 * Groups session data by date.
 *
 * @param {Array} sessionData - The array of session data to be grouped.
 * @returns {Object} - An object containing sessions grouped by date.
 */
export function groupSessionsByDate(sessionData) {
  let currentDate = null;
  let sessionsByDate = {};
  let date = null;
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set the time to 00:00:00 for accurate comparison
  const currentYear = today.getFullYear();

  // Sort sessionData by date descending
  sessionData.sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate());

  sessionData.map((session) => {
    date = session.createdAt.toDate();
    date.setHours(0, 0, 0, 0); // Set the time to 00:00:00 for accurate comparison

    let key = date.toLocaleDateString("en-gb", {
      weekday: "long",
      day: "numeric",
      month: "long",
    });

    if (date.getTime() === today.getTime()) {
      key = "Today";
    } else if (date.getFullYear() !== currentYear) {
      key += `, ${date.getFullYear()}`;
    }

    if (!currentDate || currentDate.getTime() !== date.getTime()) {
      currentDate = date;
      sessionsByDate[key] = [];
    }
    sessionsByDate[key].push(session);
  });
  return sessionsByDate;
}

export default {
  groupSessionsByDate,
};
