import React, { useContext, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import download_select from "../../images/dashboard/download_select.svg";
import download_select_dis from "../../images/dashboard/download_select_dis.svg";
import "../../style/sessionDetailedView.css";
import { UploadContext } from "../App";
import Share from "../common/ModalShareFile";
import Delete from "../common/ModalDeleteFiles";
import { downloadFiles } from "./downloadFile";
import { Tooltip } from "@mui/material";

const MultiSelect = ({
  entityId,
  isOrgUser,
  multiselectedUrls,
  multiSelectedPaths,
  setMultiSelectedPaths,
  currentUser,
  reloadCallback,
  multiselectedFiles,
  setMultiselectedFiles,
  multiSharedFiles,
  setMultiSharedFiles,
  sessionId,
  setMultiselectedUrls,
  openedFile,
  setOpenedFile,
  downloadingFiles,
  dltPermission,
  sessionOwner,
  shrPermission,
  sessionData,
  patientId,
}) => {
  const { sideBarWidth } = useContext(UploadContext);

  const [marginTop, setMarginTop] = useState(80);

  const handleDownload = (file, multiSharedFiles) => {
    downloadFiles(file, multiSharedFiles);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      if (scrollY > 80) {
        setMarginTop(0);
      } else {
        setMarginTop(80 - scrollY);
      }
    };

    handleScroll();

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const noDwlPermission =
    shrPermission === 0 ||
    (currentUser !== sessionOwner && shrPermission === 1);

  return (
    <div>
      <div
        className={`multiselect ${
          sideBarWidth === "70px" ? "sidebarWide" : ""
        } ${multiSharedFiles?.length > 0 ? "multiSelectBar" : ""}`}
        style={
          multiSharedFiles?.length > 0 ? { marginTop: `${marginTop}px` } : {}
        }
      >
        <button
          className="button_no_style close_icon"
          onClick={() => {
            {
              if (multiSharedFiles?.length > 0) {
                setMultiSharedFiles([]);
              } else {
                setMultiselectedFiles([]);
                setMultiSelectedPaths([]);
                setMultiselectedUrls([]);
              }
            }
          }}
        >
          <CloseIcon />
        </button>
        <div className="font_weight_600">
          Selected{" "}
          {multiSharedFiles?.length > 0
            ? `(${multiSharedFiles?.length})`
            : `(${multiselectedFiles?.length})`}
        </div>
        <div className="flex_auto_margin">
          {multiSharedFiles?.length > 0 ? (
            ""
          ) : (
            <Share
              multiSelectedPaths={
                Array.isArray(multiSelectedPaths)
                  ? multiSelectedPaths
                  : [multiSelectedPaths]
              }
              shrPermission={shrPermission}
              sessionOwner={sessionOwner}
              currentUser={currentUser}
              sessionData={sessionData}
              patientId={patientId}
            />
          )}
          {noDwlPermission ? (
            <Tooltip title="You don't have the required permissions to download this file">
              <button
                className="button_no_style"
                disabled={noDwlPermission}
                style={{ cursor: "auto" }}
              >
                <div className="flex_center_column">
                  <img
                    className="img_height_40"
                    src={download_select_dis}
                  ></img>
                  <div className="save_to_device">Save to Device</div>
                </div>
              </button>
            </Tooltip>
          ) : (
            <button
              className="button_no_style"
              onClick={() => {
                const filesToDownload = [];
                const files =
                  multiSharedFiles?.length > 0
                    ? multiSharedFiles
                    : multiselectedFiles;

                files.forEach((file) => {
                  const id =
                    multiSharedFiles?.length > 0
                      ? file.signedUrl
                      : file.globalId;
                  if (!downloadingFiles.has(id)) {
                    const url =
                      multiSharedFiles?.length > 0 ? file.signedUrl : file.url;
                    const name =
                      multiSharedFiles?.length > 0 ? "name" : file.globalId;

                    filesToDownload.push({
                      name: name,
                      url: url,
                    });
                  }
                });
                if (filesToDownload?.length > 0) {
                  handleDownload(filesToDownload, multiSharedFiles);
                }
              }}
            >
              <div className="flex_center_column">
                <img className="img_height_40" src={download_select}></img>
                <div className="save_to_device">Save to Device</div>
              </div>
            </button>
          )}
          {multiSharedFiles?.length > 0 ? (
            ""
          ) : (
            <Delete
              entityId={entityId}
              isOrgUser={isOrgUser}
              currentUser={currentUser}
              reloadCallback={reloadCallback}
              multiselectedFiles={multiselectedFiles}
              setMultiselectedFiles={setMultiselectedFiles}
              multiselectedUrls={multiselectedUrls}
              setMultiselectedUrls={setMultiselectedUrls}
              openedFile={openedFile}
              setOpenedFile={setOpenedFile}
              sessionId={sessionId}
              dltPermission={dltPermission}
              sessionOwner={sessionOwner}
            />
          )}
        </div>
      </div>{" "}
    </div>
  );
};

export default MultiSelect;
