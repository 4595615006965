import axios from "axios";
import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase";

/************** SUBSCRIPTIONDATA ******************/
export async function getSubscriptionData(authToken) {
  let subscriptionData = {
    hasActiveSubscription: null,
    validPaymentMethod: null,
    currentPeriodEnd: null,
  };

  if (authToken) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_CLOUD_FUNCTION_URL}/getSubscriptionDetails`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            // "Content-Type": "application/json",
          },
        }
      );
      // Handle the response
      subscriptionData.hasActiveSubscription =
        response.data.hasActiveSubscription;
      subscriptionData.validPaymentMethod = response.data.validPaymentMethod;
      const originalDate = new Date(response.data.currentPeriodEnd);
      const formattedDate = originalDate.toLocaleDateString("en-GB", {
        day: "numeric",
        month: "long",
        year: "numeric",
      });
      subscriptionData.currentPeriodEnd = formattedDate;
    } catch (error) {
      // Handle errors
      console.error("Error getting subscription data:", error);
    }
  }
  return subscriptionData;
}

export async function getIntercomHash(authToken) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_CLOUD_FUNCTION_URL}/generateIntercomHash`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error getting intercom hash:", error);
  }
}

async function getShareLinkData(linkId) {
  try {
    const getShareLinkRenderData = httpsCallable(
      functions,
      "getShareLinkRenderData"
    );
    const result = await getShareLinkRenderData({ shareLinkId: linkId });

    return result.data;
  } catch (error) {
    if (error.code) {
      switch (error.code) {
        case "invalid-argument":
          console.error(
            "400 Invalid Argument: The shareLinkId parameter is not provided."
          );
          console.error("Body: Missing required arguments.");
          throw new Error("400 Invalid Argument: Missing required arguments.");
        case "permission-denied":
          console.error("403 Permission Denied: The share link has expired.");
          console.error("Body: Share link has expired.");
          throw new Error("403 Permission Denied: Share link has expired.");
        case "not-found":
          console.error(
            "404 Not Found: The share link document does not exist."
          );
          console.error("Body: Share link not found.");
          throw new Error("404 Not Found: Share link not found.");
        case "resource-exhausted":
          console.error("429 Resource Exhausted: The rate limit is exceeded.");
          console.error("Body: Too many requests, please try again later.");
          throw new Error(
            "429 Resource Exhausted: Too many requests, please try again later."
          );
        case "internal":
          console.error(
            "500 Internal Server Error: An error occurred while retrieving the share link render data."
          );
          console.error("Body: Failed to retrieve share link render data.");
          throw new Error(
            "500 Internal Server Error: Failed to retrieve share link render data."
          );
        default:
          console.error(
            `Firebase function error [${error.code}]:`,
            error.message
          );
          throw new Error(
            `Firebase function error [${error.code}]: ${error.message}`
          );
      }
    } else {
      console.error("Unknown error:", error);
      throw new Error("Unknown error occurred.");
    }
  }
}

async function getShareLink(filePaths, sessionInfo, expiringLink) {
  try {
    let sessionDetails = {};
    if (Object.keys(sessionInfo).length !== 0) {
      const createdAt = new Date(
        sessionInfo.createdAt.seconds * 1000 +
          sessionInfo.createdAt.nanoseconds / 1000000
      );

      sessionDetails = {
        createdAt: createdAt.toISOString(),
        description: sessionInfo.description,
        patientId: sessionInfo.patientId,
        tags: sessionInfo.tags,
      };
    }
    const generateShareLink = httpsCallable(functions, "generateShareLink");
    const result = await generateShareLink({
      filePaths: filePaths,
      sessionInfo: sessionDetails,
      expiringLink: expiringLink,
    });
    return result.data;
  } catch (error) {
    if (error.code) {
      switch (error.code) {
        case "invalid-argument":
          console.error(
            "400 Invalid Argument: The shareLinkId parameter is not provided."
          );
          console.error("Body: Missing required arguments.");
          throw new Error("400 Invalid Argument: Missing required arguments.");
        case "permission-denied":
          console.error("403 Permission Denied: The share link has expired.");
          console.error("Body: Share link has expired.");
          throw new Error("403 Permission Denied: Share link has expired.");
        case "not-found":
          console.error(
            "404 Not Found: The share link document does not exist."
          );
          console.error("Body: Share link not found.");
          throw new Error("404 Not Found: Share link not found.");
        case "resource-exhausted":
          console.error("429 Resource Exhausted: The rate limit is exceeded.");
          console.error("Body: Too many requests, please try again later.");
          throw new Error(
            "429 Resource Exhausted: Too many requests, please try again later."
          );
        case "internal":
          console.error(
            "500 Internal Server Error: An error occurred while retrieving the share link render data."
          );
          console.error("Body: Failed to retrieve share link render data.");
          throw new Error(
            "500 Internal Server Error: Failed to retrieve share link render data."
          );
        default:
          console.error(
            `Firebase function error [${error.code}]:`,
            error.message
          );
          throw new Error(
            `Firebase function error [${error.code}]: ${error.message}`
          );
      }
    } else {
      console.error("Unknown error:", error);
      throw new Error("Unknown error occurred.");
    }
  }
}

export default {
  getSubscriptionData,
  getIntercomHash,
  getShareLinkData,
  getShareLink,
};
