import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

export default function PrivateRoute({ component: Component, ...rest }) {
  const { currentUser } = useAuth();
  const location = useLocation();
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("code");

  // Check if the current path includes '/s/'
  const isLinkSharing = location.pathname.includes("/s/");

  if (currentUser || isLinkSharing) {
    return <Outlet />;
  } else {
    const loginUrl = code ? `/login?code=${code}` : "/login";
    return <Navigate to={loginUrl} />;
  }
}
