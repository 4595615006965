import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import Papa from "papaparse";
import {
  Box,
  Grid,
  IconButton,
  Checkbox,
  Button,
  Stepper,
  Step,
  StepLabel,
  TextField,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import { getCanonicalPatientId } from "../../utils/patientUtils";
import firestoreRepository, {
  getNewGlobalId,
  updateLastDeviceSync,
} from "../../repositories/firestoreRepository";
import { Mp, PostAdd } from "@mui/icons-material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, isValid, parse } from "date-fns";
import { enUS, fi } from "date-fns/locale";
import {
  collection,
  doc,
  getDocs,
  query,
  serverTimestamp,
  setDoc,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import importPatient from "../dashboard/importActive.svg";
import { db } from "../../firebase";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(3),
    color: "#3D3838",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "&.MuiDialog-paper": {
    borderRadius: "30px",
  },
  "&.MuiPaper-root": {
    borderRadius: "30px",
  },
}));

const CustomStepper = styled(Stepper)({
  "& .MuiStepIcon-root.MuiStepIcon-active": {
    color: "#8F4FFF",
  },
  "& .MuiStepIcon-root.MuiStepIcon-completed": {
    color: "#8F4FFF",
  },
  "& .MuiStepIcon-root": {
    color: "#8F4FFF",
  },
  "& .MuiStepLabel-label.MuiStepLabel-active": {
    color: "#8F4FFF",
  },
  "& .MuiStepLabel-label.MuiStepLabel-completed": {
    color: "#8F4FFF",
  },
  "& .MuiStepConnector-line": {
    borderColor: "#8F4FFF",
  },
});

const BootstrapDialogContent = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogContent sx={{ m: 0, p: 2 }} {...other}>
      {onClose ? (
        <IconButton
          aria-labels="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#3D3838",
            zIndex: "10",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
      {children}
    </DialogContent>
  );
};

BootstrapDialogContent.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const CSVUpload = ({ entityId, isOrgUser, patientIds, reloadCallback }) => {
  const handleClose = () => setOpen(false);
  const [csvData, setCsvData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [patientIdDuplicate, setPatientIdDuplicate] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const [selectedFields, setSelectedFields] = useState({});
  const [selectedFieldData, setSelectedFieldData] = useState([]);
  const [validationError, setValidationError] = useState("");

  const steps = ["Import Data", "Verify fields", "Review & Create Patients"];
  const columnHeaders = [
    "Date of Birth",
    "Gender",
    "First Name",
    "Last Name",
    "Patient ID",
  ];

  useEffect(() => {
    const fetchData = async () => {
      const patientsData = await firestoreRepository.getAllPatients(
        entityId,
        isOrgUser
      );
      var tempPatients = {};
      patientsData.forEach((patient) => {
        const globalId = patient.globalId;
        const canonicalId = patient.canonicalPatientId;
        tempPatients[globalId] = canonicalId;
      });
      setPatientIdDuplicate(tempPatients);
    };
    fetchData();
  }, []);

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    Papa.parse(file, {
      header: true,
      complete: (results) => {
        setCsvData(results.data);
        setSelectedRows(results.data.map((_, index) => index));
        setActiveStep(1);
      },
    });
  }, []);

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      onDrop,
      accept: { "text/csv": [] },
    });

  const getStyle = (isFocused, isDragAccept, isDragReject) => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {}),
  });

  const style = getStyle(isFocused, isDragAccept, isDragReject);

  const handleCheckboxChange = (index) => {
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows.includes(index)) {
        return prevSelectedRows.filter((i) => i !== index);
      } else {
        return [...prevSelectedRows, index];
      }
    });
  };

  const handleSelectAll = () => {
    if (selectedRows.length === csvData.length) {
      setSelectedRows([]);
    } else {
      setSelectedRows(csvData.map((_, index) => index));
    }
  };

  const genders = [
    { value: 1, label: "Male" },
    { value: 2, label: "Female" },
    { value: 9, label: "Other" },
    { value: 0, label: "Unknown" },
  ];

  const fieldOptions = [
    { label: "Date of Birth", value: "date" },
    { label: "Patient ID", value: "patientId" },
    { label: "First Name", value: "firstName" },
    { label: "Last Name", value: "lastName" },
    { label: "Gender", value: "gender" },
    { label: "Not included", value: "null" },
  ];

  const handleFieldChange = (key, value) => {
    const selectedOption = fieldOptions.find(
      (option) => option.value === value
    );
    const label = selectedOption ? selectedOption.label : value;
    setSelectedFields((prevSelectedFields) => ({
      ...prevSelectedFields,
      [key]: value,
    }));

    setSelectedFieldData((prevSelectedFieldData) => {
      const updatedFieldData = csvData.map((row, rowIndex) => {
        const newRow = { ...prevSelectedFieldData[rowIndex] };
        newRow[label] = row[key] || "";
        return newRow;
      });

      return updatedFieldData;
    });
  };

  const handleDataChange = (rowIndex, header, value) => {
    setSelectedFieldData((prevSelectedFieldData) => {
      const updatedFieldData = [...prevSelectedFieldData];
      updatedFieldData[rowIndex] = {
        ...updatedFieldData[rowIndex],
        [header]: value,
      };
      return updatedFieldData;
    });
    setValidationError("");
  };

  const resetModalState = () => {
    setSelectedFieldData([]);
    setSelectedRows([]);
    setSelectedFields({});
  };

  const __getPatientsRef = (entityId, isOrgUser) => {
    const collectionName = isOrgUser ? "organizations" : "usersV2";
    return collection(db, `${collectionName}/${entityId}/patients/`);
  };

  async function createPatient(
    entityId,
    isOrgUser,
    patientInfo,
    patientName,
    updateLastDeviceSyncNeeded = true
  ) {
    const patientsRef = __getPatientsRef(entityId, isOrgUser);
    const patientGlobalId = getNewGlobalId(entityId, 3);
    const canonicalPatientId = getCanonicalPatientId(patientName);
    const patientData = {
      ...patientInfo,
      patientId: patientName,
      globalId: patientGlobalId,
      canonicalPatientId: canonicalPatientId,
      deleted: false,
      createdAt: serverTimestamp(),
      modifiedAt: serverTimestamp(),
    };

    // Check if there is a patient with the same canonicalPatientId
    const patientQuery = await getDocs(
      query(
        patientsRef,
        where("canonicalPatientId", "==", patientData.canonicalPatientId)
      )
    );
    if (patientQuery.docs.length > 0) {
      throw new Error("Patient with same canonicalId already exists");
    }
    await setDoc(doc(patientsRef, patientGlobalId), patientData);
    if (updateLastDeviceSyncNeeded)
      await updateLastDeviceSync(entityId, isOrgUser);
    return patientGlobalId;
  }

  async function updatePatient(
    entityId,
    isOrgUser,
    patientInfo,
    patientId,
    updatedpatientName,
    updateLastDeviceSyncNeeded = true
  ) {
    const patientsRef = __getPatientsRef(entityId, isOrgUser);
    const patientDocRef = doc(patientsRef, patientId);
    const canonicalId = getCanonicalPatientId(updatedpatientName);
    // Check if there is a patient with the same canonicalPatientId and different globalId. Only one patient with the same canonicalPatientId is allowed
    const patientQuery = await getDocs(
      query(patientsRef, where("canonicalPatientId", "==", canonicalId))
    );
    if (patientQuery.docs.length > 0 && patientQuery.docs[0].id !== patientId) {
      throw new Error("Another patient with same canonicalId already exists");
    }
    const patientData = {
      ...patientInfo,
      patientId: updatedpatientName,
      canonicalPatientId: canonicalId,
      modifiedAt: serverTimestamp(),
    };
    await updateDoc(patientDocRef, patientData, { merge: true });
    if (updateLastDeviceSyncNeeded)
      await updateLastDeviceSync(entityId, isOrgUser);
  }

  const createNewPatient = async () => {
    let hasError = false;
    for (const index of selectedRows) {
      const row = selectedFieldData[index] || {};
      const patientId = row["Patient ID"] ? row["Patient ID"] : "";
      const gender = row["Gender"];
      const firstName = row["First Name"] ? row["First Name"] : "";
      const lastName = row["Last Name"] ? row["Last Name"] : "";
      const dateStr = row["Date of Birth"] ? row["Date of Birth"] : "";
      const date = parseDate(dateStr);
      const genderIdx = parseGender(gender);
      const dateTimeStr = `${convertToISO(date)}`;
      const dateTime = new Date(dateTimeStr);
      if (!patientId || !firstName || !lastName) {
        setValidationError(
          "Patient ID, First Name and Last Name must be selected."
        );
        hasError = true;
        break;
      }
      const canonicalId = getCanonicalPatientId(patientId);
      const duplicatedPatient = Object.entries(patientIdDuplicate).find(
        ([_, value]) => value === canonicalId
      );
      let patientCreate;
      if (!duplicatedPatient) {
        const patientData = {
          // birthDate: dateTime,
          firstName: firstName,
          lastName: lastName,
          genderIdx: genderIdx,
        };
        patientCreate = await createPatient(
          entityId,
          isOrgUser,
          patientData,
          patientId,
          true
        );
      } else {
        const [duplicatedPatientGlobalId, _] = duplicatedPatient;
        const patientData = {
          // birthDate: dateTime,
          firstName: firstName,
          lastName: lastName,
          genderIdx: genderIdx,
          deleted: false,
        };
        patientCreate = await updatePatient(
          entityId,
          isOrgUser,
          patientData,
          duplicatedPatientGlobalId,
          patientId,
          false
        );
      }
      if (!hasError) {
        handleClose();
        setActiveStep(0);
        resetModalState();
        reloadCallback();
      }
    }
  };

  const convertToISO = (date) => {
    if (!(date instanceof Date) || isNaN(date)) return "";
    return format(date, "yyyy-MM-dd");
  };

  const parseDate = (value) => {
    const parsedDate = Date.parse(value);
    return isNaN(parsedDate) ? null : new Date(parsedDate);
  };

  const convertTimeToISO = (time) => {
    if (!(time instanceof Date) || isNaN(time)) return "";
    return format(time, "HH:mm");
  };

  const parseTime = (timeString) => {
    const formats = [
      "HH:mm", // 24-hour format (e.g., "14:30")
      "h:mm a", // 12-hour format with AM/PM (e.g., "2:30 PM")
      "HH:mm:ss", // 24-hour with seconds (e.g., "14:30:45")
      "h:mm:ss a", // 12-hour with seconds and AM/PM (e.g., "2:30:45 PM")
    ];

    for (let formatString of formats) {
      const parsedTime = parse(timeString, formatString, new Date(), {
        locale: enUS,
      });

      if (isValid(parsedTime)) {
        return parsedTime;
      }
    }

    return null;
  };

  function convertGender(value) {
    switch (value) {
      case 1:
        return "Male";
      case 2:
        return "Female";
      case 9:
        return "Other";
      default:
        return "Unknown";
    }
  }

  // Function to parse gender values to numeric codes
  function parseGender(value) {
    switch (value) {
      case "Male":
      case "M":
      case "1":
        return 1;
      case "Female":
      case "F":
      case "2":
        return 2;
      case "Other":
      case "O":
      case "9":
        return 9;
      default:
        return 0;
    }
  }
  return (
    <>
      <div>
        <button
          className="button_no_style"
          style={{
            marginRight: "1em",
            boxShadow: " 0px 2px 8px rgba(58, 92, 157, 0.14)",
            borderRadius: "24px",
            padding: "0px 20px",
            backgroundColor: "white",
          }}
          onClick={() => setOpen(true)}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <img src={importPatient} style={{ width: "24px" }} />
            </IconButton>

            <div style={{ fontSize: "16px" }}>Import Patients</div>
          </div>
        </button>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          maxWidth={"md"}
          fullWidth={true}
          alignItems="center"
          className="modal-sub"
          style={{ borderRadius: "30px", margin: "0" }}
        >
          <BootstrapDialogContent
            onClose={handleClose}
            dividers
            style={{
              backgroundColor: "#fff",
              padding: "0",
            }}
          >
            <>
              <Box>
                <div
                  style={{
                    backgroundColor: "#FDFDFD",
                    padding: "20px 0px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      backgroundColor: "white",
                      paddingBottom: "1em",
                      boxShadow: "0px 4px 4px 0px rgba(50, 50, 71, 0.08)",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "700",
                      }}
                    >
                      Create New Patients
                    </div>
                  </div>
                </div>

                <CustomStepper activeStep={activeStep} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </CustomStepper>

                {activeStep === 0 && (
                  <div {...getRootProps({ style })}>
                    <input {...getInputProps()} />
                    <p>Add files or drop to upload</p>
                    <p>We currently support CSV</p>
                  </div>
                )}
                {activeStep === 1 && (
                  <Grid
                    container
                    style={{
                      padding: "30px",
                    }}
                    flexDirection={"column"}
                  >
                    <div
                      style={{
                        marginBottom: "20px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Grid item xs={4} style={{ fontWeight: "bold" }}>
                        CSV column name
                      </Grid>
                      <Grid item xs={4} style={{ fontWeight: "bold" }}>
                        CSV column data
                      </Grid>
                      <Grid item xs={4} style={{ fontWeight: "bold" }}>
                        Destination field
                      </Grid>
                    </div>
                    {Object.keys(csvData[0]).map((key) => (
                      <div
                        key={key}
                        style={{
                          marginBottom: "20px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Grid item xs={4} style={{ fontWeight: "bold" }}>
                          {key}
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          {csvData.slice(0, 3).map((row, index) => (
                            <div key={index}>{row[key]}</div>
                          ))}
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            select
                            label={`Select field`}
                            fullWidth
                            variant="outlined"
                            style={{ marginTop: "10px" }}
                            value={selectedFields[key] || ""}
                            onChange={(e) =>
                              handleFieldChange(key, e.target.value)
                            }
                          >
                            {fieldOptions
                              .filter(
                                (option) =>
                                  option.value === "null" ||
                                  !Object.values(selectedFields).includes(
                                    option.value
                                  ) ||
                                  selectedFields[key] === option.value
                              )
                              .map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                          </TextField>
                        </Grid>
                      </div>
                    ))}
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setActiveStep(2)}
                      style={{ marginTop: "20px" }}
                    >
                      Next
                    </Button>
                  </Grid>
                )}
                {activeStep === 2 && (
                  <Grid
                    container
                    style={{
                      padding: "30px",
                    }}
                    flexDirection={"column"}
                  >
                    <Button onClick={handleSelectAll}>
                      {selectedRows.length === csvData.length
                        ? "Deselect All"
                        : "Select All"}
                    </Button>
                    <table>
                      <thead>
                        <tr>
                          <th></th>
                          {columnHeaders.map((header) => (
                            <th key={header}>{header}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {csvData.map((_, rowIndex) => (
                          <tr key={rowIndex}>
                            <td>
                              <Checkbox
                                checked={selectedRows.includes(rowIndex)}
                                onChange={() => handleCheckboxChange(rowIndex)}
                                style={{ color: "#8F4FFF" }}
                              />
                            </td>
                            {columnHeaders.map((header) => {
                              const fieldKey = Object.keys(
                                selectedFieldData[rowIndex] || {}
                              ).find((key) => key === header);
                              const value = fieldKey
                                ? selectedFieldData[rowIndex][fieldKey]
                                : "";
                              return (
                                <td key={header}>
                                  {header === "Date of Birth" ? (
                                    <DatePicker
                                      selected={value ? parseDate(value) : null}
                                      onChange={(date) =>
                                        handleDataChange(
                                          rowIndex,
                                          header,
                                          date ? convertToISO(date) : ""
                                        )
                                      }
                                      dateFormat="yyyy-MM-dd"
                                      customInput={
                                        <TextField
                                          variant="outlined"
                                          size="small"
                                        />
                                      }
                                    />
                                  ) : header === "Patient ID" ? (
                                    <Autocomplete
                                      options={patientIds.map(
                                        (patient) => patient.patientId
                                      )}
                                      freeSolo
                                      value={value || ""}
                                      onChange={(e, newValue) =>
                                        handleDataChange(
                                          rowIndex,
                                          header,
                                          newValue
                                        )
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          variant="outlined"
                                          size="small"
                                        />
                                      )}
                                    />
                                  ) : header === "Gender" ? (
                                    <TextField
                                      select
                                      value={parseGender(value)}
                                      onChange={(e) =>
                                        handleDataChange(
                                          rowIndex,
                                          header,
                                          convertGender(e.target.value)
                                        )
                                      }
                                      variant="outlined"
                                      size="small"
                                    >
                                      {genders.map((option) => (
                                        <MenuItem
                                          key={option.value}
                                          value={option.value}
                                        >
                                          {option.label}
                                        </MenuItem>
                                      ))}
                                    </TextField>
                                  ) : (
                                    <TextField
                                      value={value}
                                      onChange={(e) =>
                                        handleDataChange(
                                          rowIndex,
                                          header,
                                          e.target.value
                                        )
                                      }
                                      variant="outlined"
                                      size="small"
                                    />
                                  )}
                                </td>
                              );
                            })}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {validationError && (
                      <div style={{ color: "red" }}>{validationError}</div>
                    )}
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setActiveStep(1)}
                      style={{ marginTop: "20px" }}
                    >
                      Back
                    </Button>
                    {validationError ? (
                      <button
                        style={{
                          marginTop: "1em",
                          width: "60%",
                          backgroundColor: "grey",
                          padding: "15px 20px",
                          marginLeft: "auto",
                          marginRight: "auto",
                          display: "block",
                        }}
                        className="button"
                      >
                        Create {selectedRows.length} patients
                      </button>
                    ) : (
                      <button
                        style={{
                          marginTop: "1em",
                          width: "60%",
                          backgroundColor: "#8F4FFF",
                          padding: "15px 20px",
                          marginLeft: "auto",
                          marginRight: "auto",
                          display: "block",
                        }}
                        className="button"
                        onClick={() => {
                          createNewPatient();
                        }}
                      >
                        Create {selectedRows.length} patients
                      </button>
                    )}
                  </Grid>
                )}
              </Box>
            </>
          </BootstrapDialogContent>
        </BootstrapDialog>
      </div>
    </>
  );
};

export default CSVUpload;
