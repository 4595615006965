import { httpsCallable } from "firebase/functions";
import { functions } from "../../firebase";

const getSignedUrlsFromAssetsData = (files, assetsData) => {
  return files.map((file) => {
    const url = new URL(file.url);
    const filePath = decodeURIComponent(
      url.pathname.split("/").slice(2).join("/")
    );

    return {
      signedUrl: file.url,
      filePath: filePath,
    };
  });
};

const generateSignedUrls = async (files) => {
  const generateSignedUrl = httpsCallable(functions, "generateSignedUrls");

  const filePaths = files.map((file) => file.url);

  const data = { filePaths: filePaths };

  const result = await generateSignedUrl(data);

  return result.data.signedUrls;
};

const downloadFile = (fileData, delay) => {
  setTimeout(() => {
    const downloadUrl = fileData.signedUrl;
    const anchor = document.createElement("a");
    anchor.href = downloadUrl;
    anchor.download = fileData.filePath.split("/").pop(); // Use filePath for the filename
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  }, delay);
};

export const downloadFiles = async (files, assetsData) => {
  try {
    let signedUrls = [];

    if (assetsData && Object.keys(assetsData).length > 0) {
      signedUrls = getSignedUrlsFromAssetsData(files, assetsData);
    } else {
      signedUrls = await generateSignedUrls(files);
    }

    // Iterate over each signed URL and trigger download with a delay
    signedUrls.forEach((fileData, index) => {
      downloadFile(fileData, index * 1000); // 1 second delay between downloads
    });
  } catch (error) {
    console.error("Error downloading file:", error);
  }
};
